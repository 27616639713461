import React from 'react';
import SEO from '../../seo/seo';
import StructuredData from '../../seo/structured-data';
import Footer from '../footer/footer';
import Header from '../header/header';

type LayoutProps = {
  children: any;
  siteTitle: string;
  hideHeader: boolean;
  fixedFooter: boolean;
  structuredData?: any;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  siteTitle,
  hideHeader,
  fixedFooter,
  structuredData,
}: LayoutProps) => {
  return (
    <>
      <SEO title={siteTitle} />
      {structuredData ? <StructuredData {...structuredData} /> : null}
      {!hideHeader ? <Header siteTitle={siteTitle} /> : null}
      <div className="main">{children}</div>
      <Footer fixed={fixedFooter} />
    </>
  );
};

export default Layout;
