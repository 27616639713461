import { Link } from 'gatsby';
import moment from 'moment';
import React from 'react';

type FooterProps = {
  fixed: boolean;
};

const links = [
  { slug: 'impressum', title: 'Imprint' },
  { slug: 'datenschutz', title: 'Data' },
];

const Footer: React.FC<FooterProps> = ({ fixed }: FooterProps) => {
  return (
    <footer className={fixed ? 'fixed' : ''}>
      <div className="wrapper">
        <small>
          <Link to="/">jhinter.de</Link> &copy; {moment().format('YYYY')}{' '}
        </small>
        <nav>
          <ul>
            {links.map((item) => (
              <li key={item.slug}>
                <Link to={'/' + item.slug}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
