import { Link } from 'gatsby';
import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';

type HeaderProps = {
  siteTitle: string;
};

const Header: React.FC<HeaderProps> = ({ siteTitle }: HeaderProps) => (
  <header>
    <div className="wrapper">
      <h1 dangerouslySetInnerHTML={{ __html: siteTitle }}></h1>
      <Link to="/">
        <nav>
          <FaAngleLeft color="white" />
        </nav>
      </Link>
    </div>
  </header>
);

export default Header;
